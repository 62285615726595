/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  /* 1rem = 10px */
  font-size: 62.5%;
  height: 100%;
}

* {
  box-sizing: border-box;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-size: 1.6rem;
  line-height: 2rem;
  font-family: "Barlow", sans-serif;
  font-weight: 400;
}

sup {
  font-size: 60%;
  vertical-align: top;
  bottom: 0.3rem;
  position: relative;
}

input:focus,
button:focus,
select:focus {
  outline: none;
}

.mdl-homepage {
  flex: 1;
  padding: 2rem 2.5rem;
}
.mdl-homepage--home {
  position: relative;
}
.mdl-homepage.video .container__bx {
  flex-wrap: nowrap;
}

.container {
  max-width: 102.8rem;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  background-color: #fff;
}
.container__bx {
  padding: 5rem 4rem;
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
}
@media (max-width: 767.98px) {
  .container__bx {
    padding: 0;
  }
}

h1 {
  color: #3707DF;
  font-size: 3.4rem;
  font-weight: 800;
  line-height: 5.8rem;
  margin-bottom: 5rem;
}
@media (max-width: 767.98px) {
  h1 {
    font-size: 2.2rem;
    line-height: 2.8rem;
  }
}
h1 span {
  opacity: 0.3;
}

h2 {
  color: #000;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 4.8rem;
}
@media (max-width: 767.98px) {
  h2 {
    font-size: 2.4rem;
    line-height: 3.8rem;
  }
}

p {
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin: 1rem 0;
}

strong {
  font-weight: 800;
}

em, i {
  font-style: italic;
}

a {
  text-decoration: none;
  color: #3707DF;
}

article {
  background-color: #F9F9F9;
  padding: 4rem;
}
@media (max-width: 767.98px) {
  article {
    padding: 3rem;
  }
}
@media (max-width: 575.98px) {
  article {
    padding: 2rem;
  }
}
article p {
  margin-top: 0;
}

li {
  position: relative;
  margin-bottom: 1rem;
  margin-left: 4rem;
}

li::before {
  content: "";
  position: absolute;
  left: -2rem;
  top: 0.7rem;
  height: 0.6rem;
  width: 0.6rem;
  background-color: #3707DF;
  border-radius: 50%;
}

.btns {
  display: flex;
  margin-top: 2rem;
  justify-content: end;
}
@media (max-width: 767.98px) {
  .btns {
    margin-top: 0;
    justify-content: space-between;
  }
}
@media (max-width: 575.98px) {
  .btns {
    flex-flow: column;
  }
}
.btns button,
.btns a {
  margin-left: 2rem;
}
@media (max-width: 767.98px) {
  .btns button,
  .btns a {
    margin-top: 2rem;
    margin-left: 0;
  }
}
@media (max-width: 575.98px) {
  .btns button,
  .btns a {
    width: 100%;
  }
}

.responsive-iframe {
  position: relative;
  width: 100%;
  overflow: hidden;
  padding-top: 56.25%;
}
.responsive-iframe iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.subtitle_profile {
  color: #3707DF;
  font-size: 2.5rem;
  font-weight: 800;
  line-height: 5.8rem;
}
@media (max-width: 767.98px) {
  .subtitle_profile {
    font-size: 3.4rem;
    line-height: 4.8rem;
  }
}
.subtitle_profile span {
  opacity: 0.3;
}

.children_profile {
  color: #3707DF;
  font-size: 1.8rem;
  font-weight: 800;
  line-height: 5.8rem;
}
@media (max-width: 767.98px) {
  .children_profile {
    font-size: 3.4rem;
    line-height: 4.8rem;
  }
}
.children_profile span {
  opacity: 0.3;
}

.js-feedback-login {
  color: yellow;
}

table {
  border-collapse: separate;
  border-spacing: 0.1rem;
}

td, th {
  padding: 1rem;
}

.number_test {
  color: #3707DF;
  margin-top: 0;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  font-weight: 700;
}

.underline-dot {
  display: inline-block;
  position: relative;
  text-decoration: underline;
  text-decoration-color: transparent;
  opacity: 1;
}

.underline-dot::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #3707DF;
}

.underline-dot::before {
  content: "";
  position: absolute;
  bottom: -10px;
  left: -11px;
  width: 11px;
  height: 11px;
  background-color: #3707DF;
  border-radius: 50%;
}

.underline-dot-revert {
  display: inline-block;
  position: relative;
  text-decoration: underline;
  text-decoration-color: transparent;
  opacity: 1;
}

.underline-dot-revert::after {
  content: "";
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #3707DF;
}

.underline-dot-revert::before {
  content: "";
  position: absolute;
  bottom: -10px;
  right: -11px;
  width: 11px;
  height: 11px;
  background-color: #3707DF;
  border-radius: 50%;
}

.item-profile {
  max-width: 300px;
}

.flex-module-container {
  display: flex;
  gap: 40px;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
}

.btn {
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2rem;
  padding: 1rem 2rem;
  justify-content: center;
  display: flex;
  align-items: center;
  color: #fff;
  width: fit-content;
  cursor: pointer;
  font-family: "Barlow", sans-serif;
  text-align: center;
}
.btn.btn-primary {
  background-color: #3707DF;
  border: 0.1rem solid #3707DF;
}
.btn.btn-primary:hover {
  color: #3707DF;
  background-color: transparent;
}

.flex-table {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;
}
.flex-table--unity {
  gap: 2rem;
}

.flex-row {
  display: flex;
  gap: 0.2rem;
}
.flex-row--unity {
  gap: 2rem;
}
@media (max-width: 767.98px) {
  .flex-row--unity {
    display: grid;
  }
}

.flex-cell {
  flex: 1;
  padding: 0.8rem;
  text-align: left;
  background-color: #F9F9F9;
  padding-left: 2rem;
  align-content: center;
}

.flex-cell-header {
  font-weight: bold;
  background-color: #3707DF;
  color: #fff;
}

table {
  border: 0;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

table tr {
  background-color: #F9F9F9;
  border: 0.1rem solid #fff;
}

table th,
table td {
  padding: 0.8rem;
  text-align: left;
  background-color: #F9F9F9;
  padding-left: 2rem;
  align-content: center;
  border: 0.2rem solid #fff;
}

table th {
  font-weight: bold;
  background-color: #3707DF;
  color: #fff;
}

@media screen and (max-width: 600px) {
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  table tr {
    border-bottom: 0;
    display: block;
    margin-bottom: 0.5rem;
  }
  table td {
    border-bottom: 0;
    display: block;
    padding-left: 1rem;
  }
  table td::before {
    content: attr(data-label);
    float: none;
    font-weight: bold;
    text-transform: uppercase;
    display: block;
    margin-bottom: 1rem;
  }
  table td:last-child {
    border-bottom: 0;
  }
}
.grid-module-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0px;
}
@media (max-width: 767.98px) {
  .grid-module-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
.grid-module-container a:hover hr {
  background-color: #3707DF;
}

.grid-module-item {
  text-align: center;
  align-items: center;
}
.grid-module-item img {
  max-width: 500px;
  width: 100%;
}
.grid-module-item hr {
  height: 6px;
  border-radius: 5px;
  background-color: #D4D4D4;
  border-color: #D4D4D4;
  border: 0 solid;
  width: 50%;
  margin-bottom: 40px;
}

.grid-unity-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}
@media (max-width: 767.98px) {
  .grid-unity-container {
    grid-template-columns: repeat(1, 1fr);
  }
}

.grid-unity-item {
  text-align: left;
  background-color: #F9F9F9;
  color: #696969;
  display: flex;
  align-items: center;
  padding-right: 10px;
  font-size: 2rem;
  line-height: 2.4rem;
}
.grid-unity-item span {
  background-color: #ED6C05;
  color: #fff;
  font-size: 80px;
  font-weight: 800;
  width: 80px;
  height: 120px;
  margin-right: 20px;
  text-align: center;
  display: flex;
  flex: none;
  align-items: center;
  justify-content: center;
}
.grid-unity-item--disable {
  color: #696969;
  background-color: #F9F9F9;
}
.grid-unity-item--disable span {
  background-color: #c3c3c3;
}
.grid-unity-item__img {
  max-width: 12rem;
  padding-bottom: 33%;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
}
.grid-unity-item__cn {
  padding: 2.3rem 4.6rem 2.4rem 2.8rem;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 500;
}
.grid-unity-item__cn p {
  margin: 0;
}
.grid-unity-item__cn h2 {
  color: #3707DF;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 1rem;
  font-size: 1.6rem;
  line-height: 2rem;
}
.grid-unity-item__cn a {
  color: #3707DF;
  font-weight: 500;
}
.grid-unity-item__cn strong {
  font-weight: 700;
}

.grid-themes-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}
@media (max-width: 767.98px) {
  .grid-themes-container {
    grid-template-columns: repeat(2, 1fr);
  }
}

.grid-themes-item {
  background-color: #ED6C05;
  text-align: center;
  padding: 2rem;
  color: #fff;
  font-size: 2rem;
  height: 100%;
}
.grid-themes-item--test {
  color: #3707DF;
  background-color: #F9F9F9;
}
.grid-themes-item img {
  height: 4em;
}

.modal-iframe .fancybox__content {
  width: 40rem;
  height: 30rem;
}

.test-item {
  padding-bottom: 2rem;
}

.answer {
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.8rem;
}
.answer input {
  margin-right: 8px;
}
.answer input[type=radio] {
  appearance: none;
  width: 1.5rem;
  height: 1.5rem;
  border: 0.2rem solid #ccc;
  border-radius: 50%;
  outline: none;
  flex: none;
  margin-left: 0;
}
.answer input[type=radio]:before {
  content: "";
  display: block;
  width: 60%;
  height: 60%;
  margin: 20% auto;
  border-radius: 50%;
}
.answer input[type=radio]:checked {
  border: 0.2rem solid #3707DF;
}
.answer input[type=radio]:checked:before {
  background: #3707DF;
}
.answer p {
  margin-top: 0;
  margin-bottom: 0;
}

.question {
  padding-left: 3rem;
  position: relative;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-weight: 700;
  display: inline-flex;
}

.la-check {
  color: green;
  margin-right: 0.8rem;
  line-height: 2.2rem;
}

.la-times {
  color: red;
  margin-right: 0.8rem;
  line-height: 2.2rem;
}

.feedback {
  display: flex;
}
.feedback__txt p {
  margin-top: 0;
}

.form-control,
.form-select,
textarea {
  width: 100%;
  margin-bottom: 0.5rem;
  font-size: 2rem;
  font-weight: 400;
  line-height: 2rem;
  text-align: left;
  border: 0;
  color: #000;
  font-family: "Barlow", sans-serif;
  position: relative;
  border-bottom: 0.1rem solid #000;
  padding: 0.8rem 1rem;
  border-radius: 0;
}
.form-control::placeholder,
.form-select::placeholder,
textarea::placeholder {
  font-size: 2rem;
  font-weight: 400;
  line-height: 2rem;
  color: #000;
  font-family: "Barlow", sans-serif;
  opacity: 1;
}

.form-select {
  background-image: url("../imgs/form-select.svg");
  background-position: center right;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
}

textarea {
  resize: none;
}

.form-group {
  position: relative;
}

.mdl-homepage--contact form button.btn {
  font-family: "Barlow", sans-serif;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2.2rem;
  text-align: center;
  background-color: #00A4EC;
  width: 100%;
  max-width: 36.8rem;
  border-radius: 2rem;
  text-transform: uppercase;
  border: 0;
  cursor: pointer;
}

.field-icon {
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 1.4rem;
  background-image: url("../imgs/eye.svg");
  width: 3rem;
  height: 1.8rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;
}
.field-icon--on {
  background-image: url("../imgs/eye-slash.svg");
}

.aviso-hr {
  margin-top: 3rem;
  margin-bottom: 3rem;
  opacity: 0.2;
}

.iframe-box {
  position: relative;
  padding-bottom: 45%;
  height: 0;
  width: 80%;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 767.98px) {
  .iframe-box {
    width: 100%;
  }
}
.iframe-box iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.breadcrumbs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 5rem;
}
.breadcrumbs--back {
  width: 3.3rem;
}
@media (max-width: 767.98px) {
  .breadcrumbs {
    margin-bottom: 0;
  }
}
.breadcrumbs h1 {
  line-height: 3.8rem;
  margin-right: 2rem;
}

.header {
  width: 100%;
  margin: 0 auto;
  padding: 0rem;
  background-color: #fff;
}
.header__topbar {
  width: 100%;
  margin: 0 auto;
  padding: 2rem 3rem;
  height: 6.8rem;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  color: black;
}
.header__topbar strong {
  color: #00A4EC;
}
.header__topbar p {
  display: inline;
}
@media (max-width: 767.98px) {
  .header__topbar p {
    display: none;
  }
}
.header__topbar span {
  background-color: #00A4EC;
  color: #fff;
  border-radius: 50%;
  padding: 0.5em;
  margin-right: 1rem;
  width: 3.6rem;
  height: 3.6rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.header__links {
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
}
@media (max-width: 767.98px) {
  .header__links {
    align-items: left;
    justify-content: flex-end;
  }
}
.header__links a {
  color: #000;
  margin-left: 2rem;
  display: flex;
  align-items: center;
}
.header__links a:hover {
  color: #3707DF;
}
.header__links .las {
  font-size: 2.4rem;
}
.header__navbar {
  width: 100%;
  height: 16.2rem;
  margin: 0 auto;
  padding: 2.5rem 3rem;
  background-color: #C4C4C7;
  background-image: url(../imgs/header-background.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  background-position: right;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: nowrap;
  color: #fff;
  font-weight: 600;
}
@media (max-width: 767.98px) {
  .header__navbar {
    padding: 2rem 2.5rem;
    flex-direction: column;
    align-items: flex-start;
    background-size: cover;
  }
}
.header__navbar .brand {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.header__navbar .logo {
  width: 24.9rem;
  height: 4.3rem;
}
.header__navbar .sublogo {
  width: 14.3rem;
  height: 3rem;
}
.header__navbar .menu {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 991.98px) {
  .header__navbar .menu {
    display: none;
    position: absolute;
    left: 0;
    top: 23rem;
    padding: 6rem 4rem;
    flex-direction: column;
    width: 100%;
    background-color: #F9F9F9;
    z-index: 100;
  }
  .header__navbar .menu.js-active {
    display: flex;
  }
}
.header__navbar .menu a {
  color: #fff;
  margin-left: 2rem;
  font-size: 2rem;
  line-height: 2rem;
  text-transform: uppercase;
  position: relative;
  padding-bottom: 1rem;
}
@media (max-width: 991.98px) {
  .header__navbar .menu a {
    margin-left: 0;
    margin-top: 2rem;
    color: #000;
  }
}
.header__navbar .menu a.selected::after {
  background-color: #fff;
}
.header__navbar .menu a:hover::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.2rem;
  background-color: #fff;
}
.header__navbar--ozonest .menu a {
  color: #263184;
}
.header__navbar--ozonest .menu a.selected::after {
  background-color: #263184;
}
.header__navbar--ozonest .menu a:hover::after {
  background-color: #263184;
}

.m-toggle {
  display: none;
  width: 2.6rem;
  height: 2.6rem;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  z-index: 9;
  padding: 0;
  align-self: flex-end;
  margin-left: 2rem;
}
@media (max-width: 991.98px) {
  .m-toggle {
    display: block;
  }
}
.m-toggle span {
  display: block;
  position: absolute;
  height: 0.125rem;
  width: 100%;
  background: #fff;
  border-radius: 0;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
@media (max-width: 767.98px) {
  .m-toggle span {
    background-color: #fff;
  }
}
.m-toggle span:nth-child(1) {
  top: 0;
}
.m-toggle span:nth-child(2) {
  top: 0.8rem;
  right: 0;
  left: auto;
}
.m-toggle span:nth-child(3) {
  top: 0.8rem;
  max-width: 80%;
  right: 0;
  left: auto;
}
.m-toggle span:nth-child(4) {
  top: 1.6rem;
}
.m-toggle.js-active span:nth-child(1) {
  top: 1rem;
  width: 0%;
  left: 50%;
}
.m-toggle.js-active span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  max-width: 100%;
}
.m-toggle.js-active span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
  max-width: 100%;
}
.m-toggle.js-active span:nth-child(4) {
  top: 1rem;
  width: 0%;
  left: 50%;
}

.footer {
  width: 100%;
  margin: 0 auto;
  padding: 2rem 2.5rem;
  background-color: #00A4EC;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  color: #fff;
}
@media (max-width: 767.98px) {
  .footer {
    display: block;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    padding: 1.1rem 2.5rem;
  }
}
.footer a {
  color: #fff;
}
.footer a:hover {
  color: #000;
}
@media (max-width: 767.98px) {
  .footer a {
    display: inline-block;
    font-size: 1.2rem;
    margin: 0;
  }
}
.footer span {
  margin-right: 2rem;
  margin-left: 2rem;
}
@media (max-width: 767.98px) {
  .footer span {
    display: none;
  }
}
.footer p {
  display: inline;
}
@media (max-width: 767.98px) {
  .footer p {
    display: block;
    font-size: 1.2rem;
    margin: 0;
  }
}

.footerlogo {
  width: 10.67rem;
  height: 4.5rem;
}
@media (max-width: 767.98px) {
  .footerlogo {
    width: 8rem;
    height: auto;
  }
}

.home-logo-forma {
  width: 21.7rem;
  position: absolute;
  top: 0;
  left: 0;
}

.home-background {
  background-image: url("../imgs/oftacademy-bk-pc.jpg");
  background-repeat: no-repeat;
  background-color: #000;
  background-size: cover;
  background-position: bottom 8rem left;
}
@media (max-width: 991.98px) {
  .home-background {
    background-image: url("../imgs/oftacademy-bk-tablet.jpg");
    background-position: center left;
  }
}
@media (max-width: 767.98px) {
  .home-background {
    background-image: url("../imgs/oftacademy-bk-mobile.jpg");
    background-position: center left;
  }
}

.home-login-form {
  position: absolute;
  bottom: 15rem;
  left: 10rem;
  background-color: rgba(0, 164, 236, 0.4);
  padding: 2rem 3rem;
  max-width: 45rem;
  width: 100%;
}
@media (max-width: 767.98px) {
  .home-login-form {
    bottom: 2.5rem;
    left: 50%;
    transform: translateX(-50%);
    right: auto;
    max-width: 34rem;
    padding: 0.5rem 2rem;
  }
}
.home-login-form button {
  border-radius: 5rem;
  background-color: #00A4EC;
  margin: 1.6rem 0rem;
  font-size: 1.4rem;
  font-weight: 600;
  width: 100%;
  border: 0;
}
.home-login-form button:hover {
  background-color: #fff;
  color: #00A4EC;
}
@media (max-width: 767.98px) {
  .home-login-form button {
    margin: 0.8rem 0;
  }
}
.home-login-form input {
  background-color: transparent;
  margin: 1rem 0rem;
  font-size: 1.4rem;
  color: #fff;
  font-weight: 400;
  width: 100%;
  border: 0;
  border-bottom: 0.1rem solid rgba(0, 164, 236, 0.8);
  padding: 0.4rem;
  font-family: "Barlow", sans-serif;
  border-radius: 0;
}
.home-login-form input::placeholder {
  color: white;
}
.home-login-form input:focus-visible {
  outline-offset: 0rem;
  outline: 0;
}
.home-login-form input:focus::placeholder {
  color: transparent;
}
@media (max-width: 767.98px) {
  .home-login-form input {
    padding: 0.4rem;
    margin: 0.6rem 0;
  }
}
.home-login-form a {
  color: #fff;
  font-size: 1.4rem;
}