// $ios: 'iPad', 'iPhone', 'iPod', 'AppleWebKit';

.home-logo-forma{
 width:21.7rem;
 position:absolute;
 top:0;
 left:0;
}

.home-background{
    background-image: url("../imgs/oftacademy-bk-pc.jpg");
    background-repeat: no-repeat;
    background-color: $black;
    background-size:cover;
    background-position: bottom 8rem left;
    @media(max-width: $lg){
        background-image: url("../imgs/oftacademy-bk-tablet.jpg");
        background-position: center left;
    }
    @media(max-width: $md){
        background-image: url("../imgs/oftacademy-bk-mobile.jpg");
        background-position: center left;
    }
}

.home-login-form{
    position:absolute;
    bottom:15rem;
    left:10rem;
    background-color: rgba(0, 164, 236, 0.4);
    padding: 2rem 3rem;
    max-width: 45rem;
    width: 100%;
    @media(max-width: $md){
        // bottom:20vh;
        bottom: 2.5rem;
        left: 50%;
        transform: translateX(-50%);
        right: auto;
        max-width: 34rem;
        padding: .5rem 2rem;
        // @mixin ios {
        //     bottom:0;
        // }
    }
    button{
        border-radius: 5.0rem;
        background-color: $blue;
        margin: 1.6rem .0rem;
        font-size: 1.4rem;
        font-weight: 600;
        width: 100%;
        border: 0;
        &:hover{
            background-color: $white;
            color: $blue;
        }
        @media(max-width: $md){
            margin: .8rem 0;
        }

    }
    input{
        background-color: transparent;
        margin: 1rem .0rem;
        font-size: 1.4rem;
        color: $white;
        font-weight: 400;
        width: 100%;
        border: 0;
        border-bottom: .1rem solid rgba(0, 164, 236, 0.8);
        padding: .4rem;
        font-family: $font;
        border-radius: 0;

        &::placeholder {
            color: white;
        }
        &:focus-visible {
            outline-offset: .0rem;
            outline: 0;
        }
        &:focus{
            &::placeholder {
                color: transparent;
            }
        }
        @media(max-width: $md){
            padding: .4rem;
            margin: .6rem 0;
        }

    }
    a{
        color: $white;
        font-size: 1.4rem;
    }
}

