.btn{
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 2rem;
  padding: 1rem 2rem;
  justify-content: center;
  display: flex;
  align-items: center;
  color: $white;
  width: fit-content;
  cursor: pointer;
  font-family: $font;
  text-align: center;

  &.btn-primary{
    background-color: $color-primary;
    border: 0.1rem solid $color-primary;
    &:hover{
      color: $color-primary;
      background-color: transparent;
    }
  }
}