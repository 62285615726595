.iframe-box {
    position: relative;
    padding-bottom: 45%;
    height: 0;
    width:80%;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
    @media(max-width: $md){
      width:100%;
    }
    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
}