.footer{
    width: 100%;
    margin: 0 auto;
    padding: 2rem 2.5rem;
    background-color: $blue;
    display: flex;
    align-items: center;
    justify-content:space-between;
    flex-wrap: nowrap;
    color: $white;
    @media(max-width: $md){
        display: block;
        text-align: center;
        justify-content:center;
        flex-direction: column;
        padding: 1.1rem 2.5rem;

    }

    a{
        color: $white;
        &:hover{
            color: $black;
        }
        @media(max-width: $md){
            display:inline-block;
            font-size: 1.2rem;
            margin: 0;
        }
    }
    span{
        margin-right: 2rem;
        margin-left: 2rem;
        @media(max-width: $md){
            display: none;
        }
    }
    p{
        display: inline;
        @media(max-width: $md){
            display:block;
            font-size: 1.2rem;
            margin: 0;
        }
    }
}

.footerlogo{
    width: 10.67rem;
    height: 4.5rem;
    @media(max-width: $md){
        width: 8rem;
        height: auto;
    }
}
