.test-item{
  padding-bottom: 2rem;
}

.answer{
  display: flex;
  align-items: flex-start;
  margin-bottom: 0.8rem;

  & input{
    margin-right:8px;
    &[type='radio'] {
      appearance: none;
      width: 1.5rem;
      height: 1.5rem;
      border: 0.2rem solid #ccc;
      border-radius: 50%;
      outline: none;
      flex: none;
      margin-left: 0;
      &:before {
        content: '';
        display: block;
        width: 60%;
        height: 60%;
        margin: 20% auto;
        border-radius: 50%;
      }
      &:checked {
        border: 0.2rem solid $color-primary;
        &:before {
          background: $color-primary;
        }
      }
    }
  }

  p{
    margin-top: 0;
    margin-bottom: 0;
  }
}

.question{
  padding-left: 3rem;
  position: relative;
  margin-top: 2rem;
  margin-bottom: 2rem;
  font-weight: 700;
  display:inline-flex;
}

.la-check{
  color:green;
  margin-right: 0.8rem;
  line-height: 2.2rem;
}

.la-times{
  color:red;
  margin-right:0.8rem;
  line-height: 2.2rem;
}

.feedback{
  display: flex;

  &__txt{
    p{
      margin-top: 0;
    }
  }
}

.form-control,
.form-select,
textarea {
  width: 100%; 
  margin-bottom: .5rem;
  font-size: 2rem;
  font-weight: 400;
  line-height: 2rem;
  text-align: left;
  border: 0;
  color: $black;
  font-family: $font;
  position: relative;
  border-bottom: 0.1rem solid $black;
  padding: 0.8rem 1rem;
  border-radius: 0;

  &::placeholder{
    font-size: 2rem;
    font-weight: 400;
    line-height: 2rem;
    color: $black;
    font-family: $font;
    opacity: 1;
  }

}

.form-select{
  background-image: url('../imgs/form-select.svg');
  background-position: center right;
  background-repeat: no-repeat;
  -webkit-appearance: none;
  -moz-appearance: none;
}

textarea {
  resize: none;
}

.form-group{
  position: relative;
}



.mdl-homepage{
  &--contact{
    form{
      button{
        &.btn{
          font-family: $font;
          font-size: 1.4rem;
          font-weight: 600;
          line-height: 2.2rem;
          text-align: center;
          background-color: $blue;
          width: 100%;
          max-width: 36.8rem;
          border-radius: 2rem;
          text-transform: uppercase;
          border: 0;
          cursor: pointer;
        }
      }
    }
  }

}

.field-icon {
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 1.4rem;
  background-image: url('../imgs/eye.svg');
  width: 3rem;
  height: 1.8rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto;

  &--on{
    background-image: url('../imgs/eye-slash.svg');
  }
}

.aviso-hr{
    margin-top: 3rem;
    margin-bottom: 3rem;
    opacity: 0.2;
}
