.breadcrumbs{
  display: flex;

  justify-content: space-between;
  margin-bottom: 5rem;

  &--back{
    width: 3.3rem;
  }

  @media(max-width: $md){
    margin-bottom: 0;
  }

  h1 {
    line-height: 3.8rem;
    margin-right: 2rem;
  }
}
