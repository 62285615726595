html {
  /* 1rem = 10px */
  font-size: 62.5%;
  height: 100%;
}

*{box-sizing: border-box;}

body{
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  font-size: 1.6rem;
  line-height: 2rem;
  font-family: $font;
  font-weight: 400;
}

sup{
  font-size: 60%;
  vertical-align: top;
  bottom: 0.3rem;
  position: relative;
}

input,
button,
select{
  &:focus{
    outline: none;
  }
}

.mdl-homepage{
  flex: 1;
  padding: 2rem 2.5rem;

  &--home{
    // display: flex;
    position: relative;
  }

  &.video{
    .container{
      &__bx{
        flex-wrap: nowrap;
      }
    }
  }

}
.container{
  max-width: 102.8rem;
  width: 100%;
  margin: 0 auto;
  padding: 0;
  background-color: $white;

  &__bx{
    padding: 5rem 4rem;
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    @media(max-width: $md){
      padding: 0;
    }
  }
}

h1{
  color: $color-primary;
  font-size: 3.4rem;
  font-weight: 800;
  line-height: 5.8rem;
  @media(max-width: $md){
      font-size: 2.2rem;
      line-height: 2.8rem;
  }
  margin-bottom: 5.0rem;
  span{
      opacity: 0.3;
  }
}
h2{
  color: $black;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 4.8rem;
  @media(max-width: $md){
      font-size: 2.4rem;
      line-height: 3.8rem;
  }
}

p{
  font-size: 1.6rem;
  line-height: 2.2rem;
  margin: 1rem 0;
}

strong{
  font-weight: 800;
}

em, i{
  font-style: italic;
}

a{
  text-decoration: none;
  color:$color-primary
}

article{
  background-color: $color-background;
  padding: 4rem;

  @media(max-width: $md){
    padding: 3rem;
  }

  @media(max-width: $sm){
    padding: 2rem;
  }

  p{
    margin-top: 0;
  }
}


li {
  position: relative;
  margin-bottom: 1rem;
  margin-left: 4rem
}

li::before {
  content: "";
  position: absolute;
  left: -2rem;
  top: .7rem;
  height: .6rem;
  width: .6rem;
  background-color: $color-primary;
  border-radius: 50%;
}

.btns{
  display: flex;
  margin-top: 2rem;
  justify-content: end;

  @media(max-width: $md){
    margin-top: 0;
    justify-content: space-between;
  }

  @media(max-width: $sm){
    flex-flow: column;

  }


  button,
  a{
    margin-left: 2rem;

    @media(max-width: $md){
      margin-top: 2rem;
      margin-left: 0;
    }

    @media(max-width: $sm){
      width: 100%;
    }

  }
}

.responsive-iframe {
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-top: 56.25%;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

.subtitle_profile{
    color: $color-primary;
    font-size: 2.5rem;
    font-weight: 800;
    line-height: 5.8rem;
    @media(max-width: $md){
        font-size: 3.4rem;
        line-height: 4.8rem;
    }
    span{
        opacity: 0.3;
    }
  }

  .children_profile{
    color: $color-primary;
    font-size: 1.8rem;
    font-weight: 800;
    line-height: 5.8rem;
    @media(max-width: $md){
        font-size: 3.4rem;
        line-height: 4.8rem;
    }
    span{
        opacity: 0.3;
    }
  }

  .js-feedback-login{
    color: yellow;
  }

table{
    border-collapse: separate;
     border-spacing: .1rem;
}

td, th{
    padding: 1rem;
}

.number_test{
  color: $color-primary;
  margin-top: 0;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 0;
  font-weight: 700;
}

.underline-dot {
    display: inline-block;
    position: relative;
    text-decoration: underline;
    text-decoration-color: transparent;
    opacity:1;
  }

.underline-dot::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #3707DF;
  }

.underline-dot::before {
    content: '';
    position: absolute;
    bottom: -10px;
    left: -11px;
    width: 11px;
    height: 11px;
    background-color: #3707DF;
    border-radius: 50%;
  }

  .underline-dot-revert {
    display: inline-block;
    position: relative;
    text-decoration: underline;
    text-decoration-color: transparent;
    opacity:1;
  }

.underline-dot-revert::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #3707DF;
  }

.underline-dot-revert::before {
    content: '';
    position: absolute;
    bottom: -10px;
    right: -11px;
    width: 11px;
    height: 11px;
    background-color: #3707DF;
    border-radius: 50%;
  }

  .item-profile{
    max-width: 300px;
  }

  .flex-module-container {
    display: flex;
    gap: 40px;
    justify-content: center;
    align-items: center;
    margin-top: 8px;
}


