.flex-table {
  display: flex;
  flex-direction: column;
  gap: 0.2rem;

  &--unity{
    gap: 2rem;
  }
}

.flex-row {
  display: flex;
  gap: 0.2rem;

  &--unity{
    gap: 2rem;

    @media(max-width: $md){
      display: grid;
    }
  }
}

.flex-cell {
  flex: 1;
  padding: 0.8rem;
  text-align: left;
  background-color: $color-background;
  padding-left: 2rem;
  align-content: center;
}

.flex-cell-header {
  font-weight: bold;
  background-color: $color-primary;
  color:$white;
}

table {
  border: 0;
  border-collapse: collapse;
  margin: 0;
  padding: 0;
  width: 100%;
  table-layout: fixed;
  margin-bottom: 2rem;
  margin-top: 1rem;
}

table tr {
  background-color: $color-background;
  border: 0.1rem solid $white;
}

table th,
table td {
  padding: 0.8rem;
  text-align: left;
  background-color: $color-background;
  padding-left: 2rem;
  align-content: center;
  border: 0.2rem solid $white;
}

table th {
  font-weight: bold;
  background-color: $color-primary;
  color:$white;
}

@media screen and (max-width: 600px) {
  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  table tr {
    border-bottom: 0;
    display: block;
    margin-bottom: 0.5rem;
  }
  
  table td {
    border-bottom: 0;
    display: block;
    // text-align: right;
    padding-left: 1rem;
  }
  
  table td::before {
    content: attr(data-label);
    float: none;
    font-weight: bold;
    text-transform: uppercase;
    display: block;
    margin-bottom: 1rem;
  }
  
  table td:last-child {
    border-bottom: 0;
  }
}



