//font
@import url('https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
$font: 'Barlow', sans-serif;

//colors
$black: #000;
$white: #fff;
$blue: #00A4EC;
$bluedark: #263184;
$grey: #696969;

$color-primary: #3707DF;
$color-secondary: #ED6C05;
$color-tertiary: #00A6A0;
$color-quaternary: #f39c12;
$color-quinary: #8e44ad;

$color-text: #2c3e50;
$color-background: #F9F9F9;
$color-header: #C4C4C7;
$color-link: #2980b9;
$color-highlight: #f1c40f;
$color-accent: #9b59b6;


//media queries
$xxl: 1399.98px;
$xl: 1199.98px;
$lg: 991.98px;
$md: 767.98px;
$sm: 575.98px;
