.grid-module-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0px;
  @media(max-width: $md){
    grid-template-columns: repeat(1, 1fr);
  }
  a:hover hr{
    background-color: $color-primary;
  }
}

.grid-module-item {
  text-align: center;
  align-items: center;
  img {
    max-width:500px;
    width:100%;
  }
  hr {
    height: 6px;
    border-radius: 5px;
    background-color: #D4D4D4;
    border-color: #D4D4D4;
    border: 0 solid;
    width: 50%;
    margin-bottom: 40px;
  }
}



.grid-unity-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  @media(max-width: $md){
    grid-template-columns: repeat(1, 1fr);
  }
}

.grid-unity-item {
  text-align: left;
  background-color: $color-background;
  color:$grey;
  display: flex;
  align-items: center;
  padding-right: 10px;
  font-size: 2rem;
  line-height: 2.4rem;

  span{
    background-color: $color-secondary;
    color: $white;
    font-size: 80px;
    font-weight: 800;
    width: 80px;
    height: 120px;
    margin-right: 20px;
    text-align: center;
    display: flex;
    flex: none;
    align-items: center;
    justify-content: center;
  }
  &--disable{
    color:$grey;
    background-color: $color-background;
    span{
      background-color: #c3c3c3;
    }
  }

  &__img{
    max-width: 12rem;
    padding-bottom: 33%;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
  }

  &__cn{
    padding: 2.3rem 4.6rem 2.4rem 2.8rem;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 500;
    p{
        margin: 0;
    }

    h2{
      color:$color-primary;
      font-weight: 700;
      text-transform: uppercase;
      margin-bottom: 1rem;
      font-size: 1.6rem;
      line-height: 2rem;
    }

    a{
      color:$color-primary;
      font-weight: 500;
    }

    strong{
      font-weight: 700;
    }
  }
}


.grid-themes-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  @media(max-width: $md){
    grid-template-columns: repeat(2, 1fr);
  }
}

.grid-themes-item {
  background-color: $color-secondary;
  text-align: center;
  padding: 2rem;
  color: $white;
  font-size: 2rem;
  height: 100%;
  &--test{
    color:$color-primary;
    background-color: $color-background;
  }
  img{
    height: 4em;
  }
}
