.header{
  width: 100%;
  margin: 0 auto;
  padding: 0rem;
  background-color: $white;

  &__topbar{
      width: 100%;
      margin: 0 auto;
      padding: 2rem 3rem;
      height: 6.8rem;
      background-color: $white;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: nowrap;
      color: black;

          strong{
              color: $blue;

          }
          p{
              display: inline;
              @media(max-width: $md){
                  display: none;
              }
          }
          span{
              background-color:$blue;
              color: $white;
              border-radius: 50%;
              padding: 0.5em;
              margin-right: 1rem;
              width: 3.6rem;
              height: 3.6rem;
              display: inline-flex;
              justify-content: center;
              align-items: center;
          }
  }

  &__links{
      display: flex;
      justify-content: flex-end;
      margin-left: auto;

      @media(max-width: $md){
          align-items: left;
          justify-content:flex-end;
      }

      a{
          color: $black;
          margin-left: 2rem;
          display: flex;
          align-items: center;

              &:hover{
                  color: $color-primary;
              }
      }
      .las{
          font-size: 2.4rem;
      }
  }

  &__navbar{
      width: 100%;
      height: 16.2rem;
      margin: 0 auto;
      padding: 2.5rem 3rem;
      background-color: $color-header;
      background-image: url(../imgs/header-background.jpg);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: right;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      flex-wrap: nowrap;
      color: $white;
      font-weight: 600;

      @media(max-width: $md){
          padding: 2rem 2.5rem;
          flex-direction: column;
          align-items: flex-start;
          background-size: cover;

      }
      .brand{
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
      }
      .logo{
          width: 24.9rem;
          height: 4.3rem;
      }
      .sublogo{
          width: 14.3rem;
          height: 3rem;
      }


      .menu{
          display: flex;
          justify-content: flex-end;

            @media(max-width: $lg) {
                display:none;
                position:absolute;
                left:0;
                top:23rem;
                padding: 6rem 4rem;
                flex-direction: column;
                width: 100%;
                background-color: $color-background;
                z-index: 100;
                &.js-active{
                    display:flex;
                }
            }

            a{
                color:$white;
                margin-left: 2rem;
                font-size: 2rem;
                line-height: 2rem;
                text-transform: uppercase;
                position: relative;
                padding-bottom: 1rem;

                @media(max-width: $lg) {
                    margin-left: 0;
                    margin-top: 2rem;
                    color: $black;
                }

                &.selected{
                  //color: $color-primary;
                  &::after {
                    background-color: $white;
                  }
                }

                &:hover{
                    //color:$color-primary;
                    &::after {
                      content: "";
                      position: absolute;
                      bottom: 0;
                      left: 0;
                      width: 100%;
                      height: 0.2rem;
                      background-color: $white;
                    }
                }
            }

      }
      &--ozonest {
        .menu{

              a{
                  color:$bluedark;
                  &.selected{
                    &::after {
                      background-color: $bluedark;
                    }
                  }

                  &:hover{
                      &::after {
                        background-color: $bluedark;
                      }
                  }
              }
        }
      }


  }

}

.m-toggle{
  display: none;
  width: 2.6rem;
  height: 2.6rem;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
  z-index: 9;
  padding: 0;
  align-self:flex-end;
  margin-left: 2rem;

  @media (max-width: $lg){
      display: block;
  }

  & span{
      display: block;
      position: absolute;
      height: 0.125rem;
      width: 100%;
      background: #fff;
      border-radius: 0;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: .25s ease-in-out;
      -moz-transition: .25s ease-in-out;
      -o-transition: .25s ease-in-out;
      transition: .25s ease-in-out;

      @media (max-width: $md){
          background-color: $white;
      }

      &:nth-child(1) {
          top: 0;
      }

      &:nth-child(2){
          top: 0.8rem;
          right: 0;
          left: auto;
      }

      &:nth-child(3){
          top: 0.8rem;
          max-width: 80%;
          right: 0;
          left: auto;
      }

      &:nth-child(4) {
          top: 1.6rem;
      }
  }

  &.js-active{

    & span{

      &:nth-child(1) {
        top: 1rem;
        width: 0%;
        left: 50%;
      }

      &:nth-child(2) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        max-width: 100%;
      }

      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        max-width: 100%;
      }

      &:nth-child(4) {
        top: 1rem;
        width: 0%;
        left: 50%;
      }
    }
  }
}
